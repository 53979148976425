.footer {
  margin-top: auto;
}

.footer-onboard-two {
  margin-top: auto;
  margin-left: $content-padding;
  margin-right: $content-padding;
  padding: 30px 0;
  border-top: 1px solid $color-mischka;
  text-align: center;
}
