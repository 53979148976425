.edit-job-titles {
  background-color: $color-white-lilac;

  .header {
    padding: 0 $content-padding;
    padding-bottom: 12px;
    display: flex;
    height: 105px;
    background: $gradient-light-blue;

    .heading {
      margin-top: auto;
      font-family: PTRootUI-Light;
      color: $color-white;
    }

    .close-link {
      // TODO: same as nav link intro, dry
      position: absolute;
      z-index: 10;
      right: 14px;
      top: 14px;
      text-transform: uppercase;
      a {
        display: block;
        text-align: right;
        width: 120px;
        height: 40px;
      }
    }
  }

  .body {
    padding: 28px $content-padding;
    // TODO: 'jobs' & 'job' are DRY type of style, do some refactoring

    .field-label {
      margin-bottom: 0;
    }

    .jobs {
      padding-top: 12px;
      padding-bottom: 6px;
      .job {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 7px;

        .visibility-toggle {
          margin-right: 30px;
        }

        .logo-container-small {
          margin-right: 24px;
        }
      }
    }

    .past-job-titles {
      padding-top: 30px;
      border-top: 1px solid #e1e2e6;
      .logo-container-small {
        // display: none;
        img {
          opacity: 0.3;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-flow: row nowrap;

    margin-top: auto;
    padding: $content-padding;

    background-color: $color-white;

    .nav-link {
      margin-left: auto;
    }

    .action-item {
      font-family: PTRootUI-Medium;
      text-transform: uppercase;
      font-size: 10px;
      color: $color-black-pearl;
      letter-spacing: 0.28px;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .action-label {
        margin: 0 8px;
      }

      .icon-container {
        width: 32px;
        height: 32px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: $color-white;
        border: 2px solid #005fff;
        border-radius: 5px;

        img {
          height: 16px;
        }
      }
    }
  }
}
