.ticket-rating {
  padding: 20px $content-padding;
  display: flex;
  flex-flow: column;

  background-color: #f7f8fc;
  height: calc(100% - 180px);

  .ticket-wrapper {
    margin-bottom: 10px;
  }

  .time-spent-new {
    .units {
      display: flex;
      flex: row;
      justify-content: space-between;
      font-family: PTRootUI-Bold;
      font-size: 10px;

      color: #a6b2ec; // inactive
      letter-spacing: 0.7px;
      text-transform: uppercase;
      .active {
        color: #4859ee; // active
      }
    }

    .slide-container {
      position: relative;
      width: calc(100% - 57px);
      height: 24px;
      margin-top: 20px;
      margin-bottom: 26px;

      -webkit-transition: 0.2s;
      transition: opacity 0.2s;

      border-radius: 1.5px;

      .range {
        position: absolute;
        top: 10px;
        background: #cbcff8;
        width: calc(100% + 57px);
        height: 3px;
      }

      .handle {
        position: absolute;
        width: 57px; /* Set a specific slider handle width */
        height: 24px; /* Slider handle height */
        background: #4859ee;
        border-radius: 5px;

        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: PTRootUI-Medium;
          font-size: 10px;
          color: #ffffff;
          letter-spacing: 0.7px;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }

  .ticket-difficulty {
    display: flex;
    flex-flow: row;

    padding-bottom: 30px;

    * {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;

      height: 70px;
      width: 31.9%;

      border: 2px solid $color-royal-blue;
      border-radius: 5px;

      font-family: PTRootUI-Medium;
      font-size: 10px;
      color: $color-black-pearl-new;
      letter-spacing: 0.7px;
      line-height: 13px;
      text-align: center;
      text-transform: uppercase;

      &:nth-child(2) {
        margin-left: 10px;
        margin-right: 10px;
      }

      &.active {
        background-color: $color-royal-blue;
        color: $color-white;
      }
    }
  }

  .footer {
    position: relative;
  }
}
