.share-skills {
  .header {
    display: flex;
    flex-flow: column;
    flex: 0.6;
    background: $gradient-light-blue;
    .heading {
      padding-top: 20px;
      color: $color-white;
      margin-top: auto;
      margin-bottom: 12px;
      padding: 0 $content-padding;
    }
  }
  .body {
    flex: 1.6;

    .circle-section {
      display: flex;
      align-items: center;
      margin-top: 30px;
      min-height: 80px;

      .paragraph {
        margin-bottom: 14px;
        margin-left: 20px;
      }
    }

    .field-label {
      margin-top: 18px;
      margin-bottom: 12px;
    }
  }

  .footer {
    // flex: 1;
    background: #ffffff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }
}
