.icon-trash {
  fill: $color-blue-ribbon;
  &.disabled {
    fill: $color-regent-gray;
  }
  &.danger {
    fill: $color-sunset-orange;
  }
}

.icon-plus {
  height: 16px;
  width: 13px;
}

.icon-cross {
  height: 11px;
  width: 11px;
}

.icon-stop {
  fill: $color-pomergrante;
  &.inactive {
    fill: $color-royal-blue;
  }
}

.icon-visible {
  fill: $color-blue-ribbon;
}

.icon-hidden {
  fill: $color-black-pearl;
}

.icon-star {
  margin-right: 13px;
  &.fill {
    fill: #ffb934;
    stroke: none;
  }

  &.fade {
    fill: #e1e2e6;
    stroke: none;
  }
}
