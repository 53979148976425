@font-face {
  font-family: PTRootUI;
  font-weight: normal;
  src: url("../static/fonts/pt-root-ui/PT_Root_UI_Regular.otf") format("opentype");
}

@font-face {
  font-family: PTRootUI-Light;
  font-weight: lighter;
  src: url("../static/fonts/pt-root-ui/PT_Root_UI_Light.otf") format("opentype");
}

@font-face {
  font-family: PTRootUI-Medium;
  font-weight: bold;
  src: url("../static/fonts/pt-root-ui/PT_Root_UI_Medium.otf") format("opentype");
}

@font-face {
  font-family: PTRootUI-Bold;
  font-weight: bolder;
  src: url("../static/fonts/pt-root-ui/PT_Root_UI_Bold.otf") format("opentype");
}

// Define global typography classes

// HEADINGS

@mixin heading {
  font-family: PTRootUI-Bold;
  font-size: 24px;
}

.heading-one {
  @include heading;
  font-family: PTRootUI;
  font-size: 21px;
  line-height: 30px;
  // margin-bottom: 30px;
}

.heading-two {
  @include heading;
  letter-spacing: -0.2px;
  margin-bottom: 14px;
}

.heading-three {
  @include heading;
  letter-spacing: -0.2px;
  margin-bottom: 20px;
}

.heading-four {
  @include heading;
  font-family: PTRootUI-Light;
  line-height: 30px;
  letter-spacing: -0.2px;
}

// PARAGRAPHS

@mixin paragraph {
  font-family: PTRootUI;
  font-size: 18px;
}

.paragraph-one {
  @include paragraph;
  line-height: 26px;
}

.paragraph-two {
  @include paragraph;
  color: $color-black-pearl-new;
  letter-spacing: -0.15px;
  line-height: 28px;
}

.paragraph-three {
  @include paragraph;
  color: $color-black-pearl-new;
  text-align: center;
  line-height: 26px;
}

// TODO: rename to .paragraph-four {
.paragraph-dashboard-one {
  @include paragraph;
}

// TEXT LINKS

.link-text-one {
  font-family: PTRootUI;
  font-size: 18px;
  line-height: 26px;
  color: $color-blue-ribbon;
}

.link-text-two {
  font-family: PTRootUI-Medium;
  font-size: 10px;
  letter-spacing: 0.75px;
  line-height: 13px;
  color: $color-white;
}

.uppercase-label-bold {
  margin-bottom: 20px;
  font-family: PTRootUI-Bold;
  font-size: 10px;
  color: $color-black-pearl-new;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}
