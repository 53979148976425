.registration {
  background-color: $color-white;

  .header {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    padding-left: $content-padding;
    padding-right: $content-padding;

    :first-child {
      margin-top: auto;
    }
  }

  .body {
    flex: 2.5;
    min-height: 280px;

    form {
      display: flex;
      height: 100%;
      flex-flow: column nowrap;
      align-items: center;

      .cta-primary {
        margin-top: 40px;
      }
    }
  }
}
