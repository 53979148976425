.need-help {
  position: relative;

  .transition-group {
    &:not(.edit-ticket-tg) {
      background-color: $color-white-lilac;
    }
  }

  .header {
    height: 105px;
    background: $gradient-light-blue;
    color: $color-white;
    display: flex;
    flex-flow: column;

    .navigation {
      display: flex;
      flex-flow: row;
      align-items: flex-end;
      padding: 0 $content-padding;
      margin-top: auto;
      margin-bottom: 20px;

      .tab {
        display: flex;
        align-items: flex-end;

        .opacity-handle {
          opacity: 0.5;
        }
        &.active {
          .opacity-handle {
            opacity: 1;
          }
        }
      }

      .link-edit {
        display: flex;

        font-family: PTRootUI-Light;
        font-size: 24px;
        color: $color-white;
        letter-spacing: -0.2px;

        .notification-skills {
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $color-malachite;
        }
      }

      .link-opened {
        margin-left: auto;
        margin-right: 20px;
      }

      .link-opened,
      .link-solved {
        font-family: PTRootUI;
        font-size: 14px;
        color: $color-white;
        letter-spacing: -0.12px;
      }
    }
  }
}
