.thank-you {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(218deg, #5570ef 0%, #475cc4 100%);
  .heading {
    text-align: center;
    color: $color-white;
  }
}
