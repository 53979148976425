.evaluate-user {
  position: relative;
  background-color: $color-white-lilac;
  .header {
    display: flex;
    flex-flow: column;
    height: 180px;
    background: $gradient-light-blue;

    .connections-list-header {
      display: flex;
      flex-flow: row nowrap;
      overflow-x: scroll;

      margin-top: 50px;
      padding: 0 $content-padding;

      .field-label {
        margin-top: 28px;
      }

      .connection {
        display: flex;
        flex: row;

        align-items: center;

        height: 91px;
        width: calc(100vw - 72px);
        min-width: 200px;
        max-width: 320px;

        margin-right: 10px;
        margin-bottom: 10px;
        padding: 10px;

        background-color: #a6b2ec;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .avatar-wrapper {
          margin-left: 8px;
          margin-right: 18px;
          .avatar-image-container {
            height: 68px;
            width: 68px;
          }
        }

        .info-container {
          color: $color-white;
          font-family: PTRootUI;
          .name {
            font-size: 16px;
            line-height: 30px;
          }
          .circle {
            font-size: 9px;
            color: $color-white;
            text-transform: uppercase;
          }
        }

        &.active {
          background-color: $color-white;
          .info-container {
            color: $color-black-pearl-new;
            .circle {
              color: $color-regent-gray;
            }
          }
        }
      }
    }

    .current-connection {
      margin-top: 4px;
      font-family: PTRootUI;
      font-size: 9px;
      color: #ffffff;
      text-align: center;
    }
  }

  .modal-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    background: linear-gradient(218deg, rgba(85, 112, 239, 0.8) 0%, rgba(71, 92, 196, 0.8) 100%);

    .modal-close {
      position: absolute;
      top: 14px;
      right: 14px;
      font-family: PTRootUI-Medium;
      font-size: 10px;
      color: $color-white;
      letter-spacing: 0.75px;
      text-transform: uppercase;
    }

    .modal-wrapper {
      position: absolute;
      width: 310px;
      height: 555px;

      top: 40px;
      left: 0;
      right: 0;
      margin: auto;

      padding: 20px 30px;

      background: $color-white;
      box-shadow: 0 0 15px 0 #091636;
      border-radius: 10px;

      .grade-text {
        font-family: PTRootUI;
        font-size: 13px;
        color: $color-black-pearl-new;
        letter-spacing: -0.11px;
      }

      .grade {
        margin: 14px 0;
      }

      .actions {
        .action {
          display: flex;
          flex-flow: row;

          margin-bottom: 20px;

          font-family: PTRootUI;
          font-size: 13px;
          color: $color-black-pearl-new;
          letter-spacing: -0.11px;

          .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;

            margin-right: 14px;

            width: 17px;
            height: 17px;

            border-radius: 50%;
            background-color: #a6b2ec;

            .icon-check {
              width: 7px;
              height: 7px;
              path {
                fill: white;
              }
            }
            .icon-cross {
              display: initial;
              width: 7px;
              height: 7px;
              stroke: white;
            }
          }
        }
      }
    }
  }
}
