.notification-open-ticket {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 12px;
  height: 12px;
  margin-right: 5px;

  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);

  span {
    opacity: 1;
    font-family: PTRootUI-Medium;
    font-size: 8px;
    letter-spacing: 0.56px;
    color: $color-white;
  }
}
