.opened-tickets-assigned {
  padding: 0 $content-padding;
  min-height: 100%;

  .placeholder {
    height: 115px;
  }

  .sort {
    display: flex;
    flex-flow: row;
    margin-top: 22px;
    margin-bottom: 22px;

    font-family: PTRootUI-Bold;
    font-size: 10px;
    color: #a6b2ec;
    letter-spacing: 0.7px;

    .deadline-people {
      display: flex;
      flex-flow: row;
      align-items: center;
      text-transform: uppercase;

      .toggle-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0 10px;
        width: 32px;
        height: 22px;
        background-color: $color-royal-blue;
        border-radius: 11px;

        .toggle-btn {
          width: 16px;
          height: 16px;
          background-color: white;
          border-radius: 50%;
          margin: 0 2px;
        }
      }

      &.sort-by-deadline {
        .toggle-btn-container {
          justify-content: flex-start;
        }
        .deadline-text {
          color: $color-royal-blue;
        }
      }
      &.sort-by-people {
        .people-text {
          color: $color-royal-blue;
        }
      }
    }
  }

  .my-tickets-list {
    .activator {
      position: relative;
      .feedback-ticket {
        position: relative;
        left: -23px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 0px;

        font-family: PTRootUI-Light;
        font-size: 24px;
        color: #03041b;
        letter-spacing: -0.2px;
        text-align: center;

        .feedback-message {
          display: block;
        }

        .ticket-status-icon,
        .left-border,
        .section-face,
        .section-accept,
        .section-submit,
        .section-decline,
        .section-more {
          display: none !important;
        }
      }
    }
  }
}
