.final {
  // background-color: $color-black-pearl;

  background-image: linear-gradient(218deg, #5570ef 0%, #475cc4 100%);

  .body {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .heading {
      color: $color-white;
      margin-bottom: 14px;
    }
  }
}
