.nav-bar {
  position: relative;
  height: 60px;
  width: 100%;
  padding: 0 $content-padding;

  img {
    width: 9px;
    height: 16px;
    
  }

  .back,
  .forward {
    position: absolute;
    display: flex;
    bottom: 0;
    width: 140px;
    height: 160px;    
    padding-bottom: $content-padding;
  }

  .back {
    left: 0;
    padding-left: $content-padding;
    :first-child {
      margin-top: auto;
    }
  }

  .forward {
    right: 0;
    padding-right: $content-padding;
    :first-child {
      margin-top: auto;
      margin-left: auto;
    }    
  }
}
