.skill-rating-list {
  padding: 30px $content-padding;
  .skill {
    margin-bottom: 12px;

    .skill-title {
      text-transform: capitalize;
      font-family: PTRootUI;
      font-size: 18px;
      color: #03041b;
      line-height: 30px;
    }

    .rating {
      display: flex;
      flex-flow: row;
      border-bottom: 1px solid #e1e2e6;

      padding-top: 10px;
      padding-bottom: 20px;

      .star {
        margin-right: 13px;
        &.fill {
          fill: #ffb934;
          stroke: none;
        }

        &.fade {
          fill: #e1e2e6;
          stroke: none;
        }
      }

      .heart {
        margin-right: 13px;
        &.fill {
          fill: #FF544A;
          stroke: none;
        }

        &.fade {
          fill: #e1e2e6;
          stroke: none;
        }
      }

      .disable-skill {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;

        width: 17px;
        height: 17px;

        border-radius: 50%;
        background-color: #a6b2ec;

        .icon-check {
          display: none;
          width: 7px;
          height: 7px;
          path {
            fill: white;
          }
        }
        .icon-cross {
          display: initial;
          width: 7px;
          height: 7px;
          stroke: white;
        }
      }
    }

    &.disabled {
      .skill-title {
        color: $color-regent-gray;
      }
      .rating {
        .star {
          fill: #e1e2e6;
          stroke: none;
        }
        .heart {
          fill: #e1e2e6;
          stroke: none;
        }        
        .disable-skill {
          .icon-check {
            display: initial;
          }
          .icon-cross {
            display: none;
          }
        }
      }
    }
  }
}
