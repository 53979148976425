.screen-transition-container {
  &.forward-horizontal {
    transform: translateX(375px);
    transition: all 300ms ease-out;
  }

  &.entering-forward-horizontal {
    z-index: 10;
  }

  &.entered-forward-horizontal {
    transform: translateX(0px);
    z-index: 10;
  }

  &.exited-forward-horizontal {
    transform: translateX(-375px);
    z-index: -10;
  }

  &.exiting-forward-horizontal {
    transform: translateX(-375px);
    transition: all 600ms ease-out;
    z-index: -10;
  }

  &.back-horizontal {
    transform: translateX(-375px);
    transition: all 300ms ease-out;
  }

  &.entering-back-horizontal {
    z-index: 10;
  }

  &.entered-back-horizontal {
    transform: translateX(0px);
    z-index: 10;
  }

  &.exited-back-horizontal {
    transform: translateX(0px);
    z-index: -10;
  }

  &.exiting-back-horizontal {
    transform: translateX(375px);
    transition: all 600ms ease-out;
    z-index: -10;
  }

  // ----------- vertical --------------
  &.forward-vertical {
    transform: translateY(812px);
    transition: all 200ms ease-out;
  }

  &.entering-forward-vertical {
    z-index: 10;
  }

  &.entered-forward-vertical {
    transform: translateY(0px);
    z-index: 10;
  }

  &.exited-forward-vertical {
    transform: translateY(-812px);
    z-index: -10;
  }

  &.exiting-forward-vertical {
    transform: translateY(-812px);
    transition: all 400ms ease-out;
    z-index: -10;
  }

  &.back-vertical {
    transform: translateY(-812px);
    transition: all 300ms ease-out;
  }

  &.entering-back-vertical {
    z-index: 10;
  }

  &.entered-back-vertical {
    transform: translateY(0px);
    z-index: 10;
  }

  &.exited-back-vertical {
    transform: translateY(0px);
    z-index: -10;
  }

  &.exiting-back-vertical {
    transform: translateY(812px);
    transition: all 600ms ease-out;
    z-index: -10;
  }
}

.body-transition-container {
  &.forward-horizontal {
    transform: translateX(375px);
    transition: all 300ms ease-out;
  }

  &.entering-forward-horizontal {
    z-index: 10;
  }

  &.entered-forward-horizontal {
    transform: translateX(0px);
    z-index: 10;
  }

  &.exited-forward-horizontal {
    transform: translateX(-375px);
    z-index: -10;
  }

  &.exiting-forward-horizontal {
    transform: translateX(-375px);
    transition: all 600ms ease-out;
    z-index: -10;
  }

  &.back-horizontal {
    transform: translateX(-375px);
    transition: all 300ms ease-out;
  }

  &.entering-back-horizontal {
    z-index: 10;
  }

  &.entered-back-horizontal {
    transform: translateX(0px);
    z-index: 10;
  }

  &.exited-back-horizontal {
    transform: translateX(0px);
    z-index: -10;
  }

  &.exiting-back-horizontal {
    transform: translateX(375px);
    transition: all 600ms ease-out;
    z-index: -10;
  }

  // ----------- vertical --------------
  &.forward-vertical {
    transform: translateY(812px);
    transition: all 200ms ease-out;
  }

  &.entering-forward-vertical {
    z-index: 10;
  }

  &.entered-forward-vertical {
    transform: translateY(0px);
    z-index: 10;
  }

  &.exited-forward-vertical {
    transform: translateY(-812px);
    z-index: -10;
  }

  &.exiting-forward-vertical {
    transform: translateY(-812px);
    transition: all 400ms ease-out;
    z-index: -10;
  }

  &.back-vertical {
    transform: translateY(-812px);
    transition: all 300ms ease-out;
  }

  &.entering-back-vertical {
    z-index: 10;
  }

  &.entered-back-vertical {
    transform: translateY(0px);
    z-index: 10;
  }

  &.exited-back-vertical {
    transform: translateY(0px);
    z-index: -10;
  }

  &.exiting-back-vertical {
    transform: translateY(812px);
    transition: all 600ms ease-out;
    z-index: -10;
  }
}
