.dashboard {
  background-color: $color-white-lilac;
  position: relative;

  .header {
    background: $gradient-light-blue;
    color: $color-white;

    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 177px;
    z-index: 100;

    transition: all 200ms ease-out;

    &.closed {
      top: -108px;
      .bottom {
        .navigation {
          padding-top: 9px;
        }
      }
    }

    .top {
      height: 130px;
      display: flex;
      flex-flow: row;
      overflow: hidden;

      .user-info {
        display: flex;
        flex-flow: row nowrap;
        height: 70px;

        margin-left: 16px;
        margin-top: 28px;

        .avatar {
          width: 70px;
          height: 70px;
          border-radius: 50%;
        }

        .name {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;

          margin-left: 12px;

          // TODO: this font type is not DRY
          font-family: PTRootUI-Medium;
          font-size: 10px;
          color: #ffffff;
          letter-spacing: 0.75px;
          line-height: 12px;
          text-transform: uppercase;
        }
      }

      .user-badges {
        margin-left: auto;
        padding-top: 3px;
        padding-right: 3px;
      }
    }
    .bottom {
      .navigation {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        padding: 0 $content-padding;
        margin-bottom: 20px;

        .tab {
          opacity: 0.5;
          &.active {
            opacity: 1;
          }
        }

        .link-skills {
          display: flex;

          font-family: PTRootUI-Light;
          font-size: 24px;
          color: $color-white;
          letter-spacing: -0.2px;

          .notification-skills {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $color-malachite;
          }
        }

        .link-job-titles {
          margin-left: auto;
          margin-right: 20px;
        }

        .link-job-titles,
        .link-connections {
          font-family: PTRootUI;
          font-size: 14px;
          color: $color-white;
          letter-spacing: -0.12px;
        }
      }
    }
  }

  .transition-group {
    position: absolute;
    top: 0px;
    transition: all 200ms ease-out;

    .skills-dashboard {
      transition: all 200ms ease-out;
    }

    &.opened {
      .skills-dashboard,
      .job-titles,
      .connections {
        margin-top: 177px;
      }

      .skills-dashboard {
        margin-bottom: 177px;
      }

      .job-titles,
      .connections {
        min-height: calc(100% - 177px);
      }
    }

    &.closed {
      .skills-dashboard,
      .job-titles,
      .connections {
        margin-top: 0px;
      }
    }
  }
}
