.Layout {
  max-width: $app-container-max-width;
  min-width: $app-container-min-width;

  height: 100vh;
  // height: 100%; // new container height method

  margin-left: auto;
  margin-right: auto;

  position: relative;
  overflow: hidden;

  font-family: Helvetica;
}
