.splash {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;

    .top-circle {
      margin-top: -306px;
      height: 481px;
      min-width: 503px;
      background-image: linear-gradient(218deg, #4859ee 0%, #475cc4 100%);
      border-radius: 50%;
    }
  }

  .body {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .heading {
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    .logo-title {
      width: 140px;
      // stroke: #005fff;
      fill: #4859ee;
    }
  }

  .footer {
    .cta-primary {
      margin-bottom: 40px;
      text-transform: uppercase;
    }
  }
}
