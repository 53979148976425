@mixin input {
  min-width: 280px;
  width: 100%;
  height: 52px;

  display: block;

  margin-left: auto;
  margin-right: auto;

  margin-top: 12px;
  margin-bottom: 12px;

  padding-left: 20px;

  -webkit-appearance: none;

  font-family: PTRootUI;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
}

.input-field {
  // TODO: used only for search func, refactor + DRY!
  @include input;

  background: #fefefe;

  border: 1px solid #d0daff;
  border-radius: 26px;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);

  color: $color-black-pearl-new;

  &::placeholder {
    color: $color-black-pearl-new;
  }

  &:focus {
    border: 1px solid $color-blue-ribbon;
    box-shadow: none;
  }

  &.error {
    border: 1px solid $color-pomergrante;
  }
}

.input-field-dark {
  @include input;
  background: #091636;
  border: 1px solid $color-white;
  border-radius: 26px;
  color: $color-white;

  &::placeholder {
    color: $color-dusty-gray;
  }
}

.input-field-text {
  @include input;
  // background: rgba(0, 95, 255, 0.03);
  background: #fefefe;

  padding: 10px;

  border: 1px solid #d0daff;
  border-radius: 10px;

  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);

  -webkit-appearance: none;

  font-size: 14px;
  color: $color-black-pearl;

  &::placeholder {
    color: $color-black-pearl;
  }

  &:focus {
    border: 1px solid $color-blue-ribbon;
    box-shadow: none;
  }

  &.error {
    border: 1px solid $color-pomergrante;
  }
}

.input-field-phone {
  width: 280px;

  margin-top: 46px;

  margin-left: auto;
  margin-right: auto;

  .label-aux {
    height: 28px;
    font-family: PTRootUI;
    font-size: 11px;
    color: $color-dusty-gray;
    line-height: 26px;
  }

  .input-container {
    position: relative;
    width: 280px;
    height: 70px;

    .label {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;

      text-transform: uppercase;

      margin-left: 15px;
      margin-top: 6px;

      font-family: PTRootUI-Bold;
      font-size: 10px;
      color: $color-dusty-gray;
      letter-spacing: 0.42px;
      line-height: 26px;

      &.error {
        // color: $color-pomergrante;
      }
    }

    .input-wrapper {
      width: 100%;
      height: 100%;
      position: relative;

      .prefix {
        position: absolute;
        bottom: 12px;
        left: 15px;
        z-index: 10;

        font-family: PTRootUI-Medium;
        font-size: 21px;
        color: $color-black-pearl;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        padding-left: 27px;
        padding-top: 22px;

        border: 1px solid #d0daff;
        border-radius: 10px;

        background: #fefefe;
        box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);

        -webkit-appearance: none;

        font-family: PTRootUI-Medium;
        font-size: 21px;
        color: $color-black-pearl;

        &:before {
          width: 10px;
          height: 10px;
          content: "neki";
          background-color: yellow;
          color: red;
          font-weight: bold;
        }

        &::placeholder {
          color: $color-regent-gray;
        }

        &:focus {
          border: 1px solid #4859ee;
          box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }

        &.error {
          border: 1px solid $color-pomergrante;
          background-color: $color-white;
          box-shadow: none;
        }

        &.valid {
          border: 1px solid $color-malachite;
        }
      }
    }
  }
}

.input-field-pin {
  width: 280px;

  .error-field {
    height: 20px;
    margin-bottom: 10px;
    text-align: center;

    .error-message {
      text-transform: uppercase;
      font-family: PTRootUI-Bold;
      font-size: 10px;
      color: red;
      letter-spacing: 0.42px;
      line-height: 26px;
    }
  }

  .input-wrapper {
    position: relative;

    input {
      width: 62px;
      height: 90px;

      &:not(:first-child) {
        margin-left: 10px;
      }

      border: 1px solid #c5c5c5;
      border-radius: 10px;
      -webkit-appearance: none;

      font-size: 36px;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;

      &:focus {
        // background-color: rgba(0, 95, 255, 0.03);
        border: 1px solid #005fff;
      }

      &.error {
        // border: 1px dotted red !important;
      }
    }
  }
}

.input-field-date {
  display: flex;
  flex-flow: row;
  -webkit-appearance: none;
  &::-webkit-calendar-picker-indicator {
    /* Your CSS here */
    display: none;
  }
  &::-webkit-inner-spin-button {
    /* Your CSS here */
    display: none;
  }
  text-align: center;

  width: 120px;
  height: 37px;

  padding-left: 12px;

  background: #fefefe;

  border: 1px solid #d7dfff;
  border-radius: 10px;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.3);

  font-family: PTRootUI;
  font-size: 15px;
  color: $color-black-pearl;

  &::placeholder {
    color: $color-black-pearl;
  }

  &:focus {
    border: 1px solid $color-blue-ribbon;
    // background-color: rgba(0, 95, 255, 0.03);
  }

  &.error {
    border: 1px solid $color-pomergrante;
  }
}

.input-field-time {
  -webkit-appearance: none;
  display: flex;
  flex-flow: row;
  text-align: center;

  &::-webkit-calendar-picker-indicator {
    /* Your CSS here */
    // display: none;
  }
  &::-webkit-inner-spin-button {
    /* Your CSS here */
    display: none;
  }

  width: 70px;
  height: 37px;

  padding-left: 8px;

  background: #fefefe;

  border: 1px solid #d7dfff;
  border-radius: 10px;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.3);

  font-family: PTRootUI;
  font-size: 15px;
  color: $color-black-pearl;

  text-align: center;
}

.input-field-connection {
  display: flex;
  flex-flow: row;

  justify-content: center;
  align-items: center;

  border: 2px solid $color-royal-blue;
  border-radius: 5px;

  padding: 4px;

  background: #fdfeff;

  .name {
    font-family: PTRootUI-Medium;
    font-size: 10px;
    color: $color-black-pearl;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    padding-left: 4px;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 34px;
    height: 34px;

    margin-left: 18px;

    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);

    overflow: hidden;

    img {
      max-height: 25px;
      max-width: 25px;
    }
  }

  &.active {
    background-color: $color-royal-blue;
    border: 2px solid $color-royal-blue;
    .name {
      color: $color-white;
    }
  }
}

.input-field-title {
  @include input;
  height: 65px;
  margin-top: 6px;
  padding: 4px 10px;

  background: #fefefe;

  border: 1px solid #d0daff;
  border-radius: 10px;

  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);

  -webkit-appearance: none;

  overflow-wrap: break-word;

  font-size: 14px;
  color: $color-black-pearl;

  &::placeholder {
    color: $color-black-pearl;
  }

  &:focus {
    border: 1px solid $color-blue-ribbon;
    box-shadow: none;
  }

  &.error {
    border: 1px solid $color-pomergrante;
  }
}

.input-field-description {
  @include input;
  min-height: 80px;
  margin-top: 6px;
  padding: 4px 10px;

  background: #fefefe;
  border: 1px solid #d0daff;
  border-radius: 10px;

  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);

  -webkit-appearance: none;

  font-size: 14px;
  color: $color-black-pearl;

  &::placeholder {
    color: $color-black-pearl;
  }

  &:focus {
    border: 1px solid $color-blue-ribbon;
    box-shadow: none;
  }

  &.error {
    border: 1px solid $color-pomergrante;
  }
}

.input-field-issue {
  @include input;
  min-height: 180px;
  margin-top: 6px;
  padding: 4px 10px;

  background: #fefefe;
  border: 1px solid #d0daff;
  border-radius: 10px;

  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);

  -webkit-appearance: none;

  font-size: 14px;
  color: $color-black-pearl;

  &::placeholder {
    color: $color-black-pearl;
  }

  &:focus {
    border: 1px solid $color-blue-ribbon;
    box-shadow: none;
  }

  &.error {
    border: 1px solid $color-pomergrante;
  }
}

.input-field-file {
  display: none;
}
