.header {
  .steps-container {
    display: flex;
    flex-flow: row;
    margin-top: 30px;
    padding: 0 $content-padding;

    .step-marker {
      height: 14px;
      width: 14px;
      background-color: #a6b2ec;
      border-radius: 50%;

      &.active {
        width: 22px;
        background-color: #f7f8fc;
        border-radius: 7px;
      }
    }
    :not(:first-child) {
      margin-left: 8px;
    }
  }
}

.header-onboard-two {
  text-align: center;
}

.header-onboard-add-info-steps {
  display: flex;
  flex-flow: column;
  background-color: $color-black-pearl;

  .heading {
    padding-top: 20px;
    color: $color-white;
    margin-top: auto;
    margin-bottom: 12px;
    padding: 0 $content-padding;
    padding-top: 20px;
  }
}

.header-dashboard-profile {
  display: flex;
  flex-flow: column;
  height: 180px;
  background-color: $color-black-pearl;

  .steps-container {
    margin-top: 30px;
    margin-left: 20px;
    img {
      height: 15px;
    }
    :not(:first-child) {
      margin-left: 15px;
    }
  }

  .heading-onboard-four {
    margin-top: auto;
    margin-bottom: 12px;
    margin-left: 20px;
  }
}
