.report {
  background: $gradient-light-blue;

  .header {
    // height: 60px;
    display: flex;
    flex-flow: column;
    padding: 0 $content-padding;

    .close-link {
      position: absolute;
      z-index: 10;
      right: 14px;
      top: 14px;
      text-transform: uppercase;
      a {
        display: block;
        text-align: right;
        width: 120px;
        height: 40px;
      }
    }

    .heading {
      margin-top: 28px;
      color: $color-white;
    }

    .paragraph {
      font-family: PTRootUI;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: -0.12px;
      margin-top: 22px;
      margin-bottom: 30px;
    }
  }

  .body {
    .input-field-issue {
      margin-bottom: 20px;
    }
  }
}
