.skills-dashboard {
  display: flex;
  flex-flow: column;
  padding: 28px $content-padding;
  height: calc(100% - 180px);
  padding-bottom: 10px;

  background-color: $color-white-lilac;

  .new-skills {
    margin-bottom: 16px;
  }

  .confirmed-skills {
    padding-bottom: 12px;
    margin-bottom: 28px;
    border-bottom: 1px solid $color-mischka;
  }

  .edit-skills-link {
    margin-top: auto;
    margin-bottom: 10px;
    color: $color-royal-blue;
    display: block;
    text-align: right;
    text-transform: uppercase;
    line-height: 13px;
  }
}

.footer-skills {
  position: fixed;
  bottom: 0;

  display: flex;
  flex-flow: row;

  z-index: 10;
  width: 100%;

  padding: 0 5.33%;
  justify-content: center;

  background-color: $color-white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}
