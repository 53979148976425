.help-more {
  background-color: $color-white;
  .header {
    height: 40px;
    display: flex;
    // background-color: $color-black-pearl; // DEBUG

    .link {
      margin-left: auto;
      margin-right: 6px;
      margin-top: 12px;
      a {
        display: block;
        text-align: right;
        width: 120px;
        height: 40px;
        color: $color-black-pearl;
      }
    }
  }
}
