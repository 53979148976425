.add-basic-info {
  background-color: $color-white;

  .header {
    display: flex;
    flex-flow: column;
    flex: 0.4;
    min-height: 120px;
    background: $gradient-light-blue;

    .heading {
      padding-top: 20px;
      color: $color-white;
      margin-top: auto;
      margin-bottom: 12px;
      padding: 0 $content-padding;
    }
  }

  .body {
    flex: 1;

    .paragraph {
      margin-top: 40px;
    }
  }

  .footer {
    .cta-primary {
      margin-bottom: 40px;
    }
  }
}
