// Screen transition container animates full viewport so its
// position is by definition absolute
.screen-transition-container {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: $color-white;

  & > div:first-child {
    height: 100%;
  }
}

// --------------------

// Body transition is a partial navigation transition that is relative to screen
// layout, while its children (routes) are absolutely positioned inside that container
.transition-group {
  // AKA body
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  flex: 1;

  .body-transition-container {
    position: absolute;
    height: auto;
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column;
  }
}

// --------------------

.loading-screen {
  display: none;
  position: absolute;
  z-index: -20;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;

  top: 0;

  background-color: rgba(0, 0, 0, 0.2);

  &.active {
    display: flex;
    z-index: 20;
  }
}

// --------------------

.app-update-notification {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.7);

  &.active {
    display: flex;
  }

  .modal-window {
    position: relative;

    padding: 12px;
    padding-top: 36px;
    max-width: 310px;

    background: white;
    font-family: PTRootUI-Medium;
    font-size: 18px;
    letter-spacing: -0.2px;

    .close-icon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px;
      font-size: 24px;
    }
  }
}

// --------------------

.container,
.container-onboard,
.container-dashboard,
.container-profile,
.container-need-help {
  display: flex;
  flex-flow: column nowrap;
}

.scrollable {
  overflow: auto;
}
