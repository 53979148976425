.my-skills {
  min-height: calc(100% - 70px);
  padding: 0 $content-padding;

  background-color: $color-white-lilac;

  .tags-list {
    margin-top: 18px;
    padding-bottom: 16px;
  }

  .edit-skills-link {
    color: $color-royal-blue;
    display: block;
    text-align: right;
    text-transform: uppercase;
    line-height: 13px;
  }
}

.my-skills-footer {
  display: flex;
  flex-flow: row nowrap;

  justify-content: center;
  width: 100%;

  position: sticky;
  bottom: 0;
  margin-top: auto;
  padding: $content-padding;

  background-color: $color-white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

  :nth-child(2) {
    margin-left: auto;
  }

  .action-item {
    font-family: PTRootUI-Medium;
    text-transform: uppercase;
    font-size: 10px;
    color: $color-black-pearl;
    letter-spacing: 0.28px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .action-label {
      margin: 0 8px;
      &.disabled {
        color: $color-regent-gray;
      }
    }

    .icon-container {
      width: 32px;
      height: 32px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: $color-white;
      border: 2px solid #005fff;
      border-radius: 5px;

      &.disabled {
        border-color: $color-regent-gray;
      }
      &.danger {
        border-color: $color-sunset-orange;
      }
    }
  }
}
