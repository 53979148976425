.edit-skills {
  background-color: $color-white-lilac;
  position: relative;

  .header {
    color: $color-white;

    .top {
      display: flex;
      height: 105px;
      padding: 0 $content-padding;
      padding-bottom: 14px;

      background: $gradient-light-blue;
      // background-color: $color-black-pearl;

      .close-link {
        // TODO: same as nav link intro, dry
        position: absolute;
        z-index: 10;
        right: 14px;
        top: 14px;
        text-transform: uppercase;
        a {
          display: block;
          text-align: right;
          width: 120px;
          height: 40px;
        }
      }

      .heading {
        margin-top: auto;
        font-family: PTRootUI-Light;
      }
    }

    .bottom {
      background-color: $color-white-lilac;
      padding: 30px $content-padding;
      .skill-tabs {
        display: flex;
        flex-flow: row nowrap;

        .tab {
          margin-right: 10px;

          .avatar-wrapper {
            width: 80px;
            min-width: 80px;
            height: 80px;
            min-height: 80px;

            display: flex;
            align-items: center;
            justify-content: center;

            background: $color-white;
            border-radius: 5px;
            overflow: hidden;

            .avatar-image-container {
              width: 68px;
              height: 68px;
            }

            &.disabled {
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
              img {
                opacity: 0.3;
              }
            }
          }
        }
      }
    }
  }
}
