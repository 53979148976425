@mixin tag {
  display: flex;

  align-items: center;
  height: 27px;

  border: 1px solid $color-royal-blue;
  border-radius: 5px;

  padding: 0 7px;
  text-transform: uppercase;

  color: $color-black-pearl-new;
  font-family: PTRootUI-Medium;
  font-size: 10px;
  letter-spacing: 0.7px;
  line-height: 9px;
}

@mixin tag-small {
  @include tag;
  height: 15px;
  padding: 0 3px;

  border-radius: 3px;
  background: #fdfeff;

  font-family: PTRootUI-Medium;
  font-size: 8px;
  letter-spacing: 0.56px;
  line-height: 6px;
  white-space: nowrap;
}

.tag-regular {
  @include tag;
  background: #fdfeff;
  color: #03041b;
  &.hidden {
    opacity: 0.5;
  }
}

.tag-unconfirmed {
  @include tag;
  background: transparent;
  color: #03041b;
  border: 1px solid $color-regent-gray;
  &.hidden {
    opacity: 0.5;
  }
}

.tag-active {
  @include tag;
  background: #4859ee;
  color: $color-white;
}

.tag-inactive {
  @include tag;
  color: #03041b;
  border: 1px solid #92989e;
}

.tag-secondary {
  @include tag;
  span {
    opacity: 0.5;
    color: #03041b;
  }
}

.tag-approved {
  @include tag;
  background-color: $color-white;
  border: 1px solid $color-malachite;
}

.tag-delete {
  @include tag;
  background: $color-white;
  border: 1px solid $color-sunset-orange;
  color: $color-sunset-orange;
}

.tag-small-active {
  @include tag-small;
}

.tag-small-regular {
  @include tag-small;
  border: 1px solid #92989e;
}

.tag-new {
  @include tag;
  position: relative;
  background-color: $color-white;
  border: 1px solid $color-malachite;
  .notification-dot {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $color-malachite;
  }
}

.tags-container {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;

  & > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.tags-container-ticket {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;

  overflow-x: scroll;
  margin-right: 2px;

  & > * {
    margin-right: 4px;
  }
}

.tag-circle-connection,
.tag-job-title {
  @include tag;
  background-color: $color-white;
  border: 1px solid $color-royal-blue;
  color: $color-black-pearl;

  padding: 0;
  width: fit-content;

  display: flex;
  flex-flow: row;

  .title,
  .year {
    height: 100%;
    padding: 0 7px;
    display: flex;
    align-items: center;
  }

  .title {
    word-wrap: break-word;
    min-width: 0;
    flex: 1;
    width: fit-content;
    text-align: justify;
  }

  .year {
    border-left: 1px solid $color-royal-blue;
  }

  &.finished {
    border-color: #92989e;
    background-color: transparent;
    .year {
      border-color: #92989e;
    }
  }
}

.tags-list {
  .tags-list-row {
    display: flex;
    flex-flow: row;
    margin-bottom: 8px;
    align-items: center;

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      width: 22px;
      margin-right: 20px;
      &.disabled {
      }
    }
  }
}
