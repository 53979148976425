.add-skills-dashboard {
  background-color: $color-white-lilac;

  .header {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 180px;
    padding: 10px $content-padding;

    background: $gradient-light-blue;

    .close-link {
      // TODO: same as nav link intro, DRY!
      position: absolute;
      z-index: 10;
      right: 14px;
      top: 14px;
      text-transform: uppercase;
      a {
        display: block;
        text-align: right;
        width: 120px;
        height: 40px;
      }
    }

    .heading {
      margin-top: auto;
      margin-bottom: 6px;
      font-family: PTRootUI-Light;
      color: $color-white;
    }

    .input-field {
      background: $gradient-light-blue;
      border: 1px solid $color-white;
      border-radius: 26px;
      color: $color-white;

      &::placeholder {
        color: $color-regent-gray;
      }
    }
  }

  .body {
    min-height: calc(100% - 180px);

    .paragraph {
      margin-top: 34px;
      margin-bottom: 20px;
    }

    .section-search-input {
      .input-field {
        margin-bottom: 16px;
      }
    }

    .section-search-results {
      margin-top: 26px;
      margin-bottom: 20px;
    }

    .section-search-selected {
      margin-top: 10px;
      padding-top: 26px;
      border-top: solid 1px $color-mischka;
      .tag {
        border-color: $color-alto;
      }
    }

    .footer {
      .cta {
        margin-bottom: 40px;
      }
    }
  }
}
