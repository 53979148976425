.confirmation-ok {
  background-color: $color-white;
  .header {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    padding-left: $content-padding;
    padding-right: $content-padding;

    text-align: center;

    :first-child {
      margin-top: auto;
    }
  }

  .body {
    flex: 2;
    justify-content: center;
    align-items: center;

    .woohoo-image {
      // margin: 24px 0;
    }
  }

  .footer {
    .cta-primary {
      margin-bottom: 40px;
    }
  }
}
