.edit-profile {
  // background-color: $color-black-pearl;
  background-image: $gradient-light-blue;

  .header {
    height: 100px;

    .close-link {
      // TODO: same as nav link intro, dry
      position: absolute;
      z-index: 10;
      right: 14px;
      top: 14px;
      text-transform: uppercase;
      a {
        display: block;
        text-align: right;
        width: 120px;
        height: 40px;
      }
    }
  }

  .body {
    .cta {
      margin-top: 40px;
    }
  }
}
