.offer-help {
  position: relative;
  background-color: $color-white-lilac;

  .header {
    height: 105px;
    background: $gradient-light-blue;
    color: $color-white;
    display: flex;
    flex-flow: column;

    .navigation {
      display: flex;
      flex-flow: row;
      align-items: flex-end;
      padding: 0 $content-padding;
      margin-top: auto;
      margin-bottom: 20px;

      .tab {
        .opacity-handle {
          opacity: 0.5;
        }
        &.active {
          .opacity-handle {
            opacity: 1;
          }
        }
      }

      .link-opened {
        display: flex;

        font-family: PTRootUI-Light;
        font-size: 24px;
        color: $color-white;
        letter-spacing: -0.2px;

        .notification-open-ticket {
          margin-top: auto;
          margin-bottom: 2px;
          margin-left: 5px;
        }
      }

      .link-solved {
        margin-left: auto;
        font-family: PTRootUI;
        font-size: 14px;
        color: $color-white;
        letter-spacing: -0.12px;
      }
    }
  }
}
