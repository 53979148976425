.profile {
  background: $gradient-light-blue;

  .divider {
    margin: 0 $content-padding;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .header {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 $content-padding;
    padding-bottom: 40px;

    .top {
      display: flex;
      flex-flow: row nowrap;
      .screen-title {
        margin-top: 28px;
        font-family: PTRootUI-Light;
        font-size: 24px;
        color: #ffffff;
        letter-spacing: -0.2px;
      }
      .close-button {
        position: absolute;
        z-index: 10;
        right: 14px;
        top: 14px;
        a {
          display: block;
          text-align: right;
          text-transform: uppercase;
          width: 120px;
          height: 40px;
        }
      }
    }

    .bottom {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 20px;

      .user-info {
        display: flex;
        flex-flow: row nowrap;

        margin-top: auto;
        padding-bottom: 30px;

        .avatar-image-container {
          width: 136px;
          height: 136px;
        }

        .upload-avatar-icon {
          position: absolute;
          bottom: 0;
          left: 0;
        }

        .name {
          display: flex;
          flex-flow: column nowrap;

          margin-left: 4px;

          font-family: PTRootUI-Medium;
          font-size: 10px;
          color: #ffffff;
          letter-spacing: 0.75px;
          line-height: 12px;
          text-transform: uppercase;

          .pen-icon {
            width: 10px;
            height: 10px;
            margin-bottom: 10px;
          }
        }
      }

      .log-out {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-top: auto;
        text-transform: uppercase;
        svg {
          margin-left: 8px;
        }
      }
    }
  }

  .body {
    color: $color-white;

    padding: 0;
    padding-top: 30px;
    padding-bottom: 20px;

    font-family: PTRootUI;
    font-size: 14px;
    color: $color-white;
    letter-spacing: -0.12px;

    .text {
      margin: 0 $content-padding;
    }

    .user-badges {
      height: 232px;
      margin: 28px 0;
      .badges-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .badges-grid-profile {
          margin-left: -40px;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .version {
      font-family: PTRootUI-Medium;
      font-size: 10px;
      text-transform: uppercase;
      color: #ffffff;
      letter-spacing: 0.75px;
      padding: $content-padding;
    }

    .bug-link {
      display: flex;
      flex-flow: row nowrap;
      margin-left: auto;
      padding: $content-padding;

      align-items: center;

      text-transform: uppercase;

      :first-child {
        margin-left: auto;
        margin-right: 6px;
      }
    }
  }
}
