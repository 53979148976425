@mixin cta {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 280px;
  height: 50px;
  min-height: 50px;

  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 40px;
  margin-bottom: 20px;
  // margin-top: 40px;
  margin-top: 20px;

  border-width: 2px;
  border-style: solid;
  border-radius: 25px;

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);

  :first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

.cta-primary {
  @include cta;

  background-color: $color-royal-blue;
  border-style: none;

  color: $color-white;
  font-family: PTRootUI;
  font-size: 18px;
  letter-spacing: 0;

  &.disabled {
    background-color: $color-regent-gray;
  }
}

.cta-primary-light {
  @include cta;
  background-color: $color-white;
  border-style: none;

  color: #03041b;
  font-family: PTRootUI;
  font-size: 18px;
  letter-spacing: 0;

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

@mixin cta-secondary {
  @include cta;
  width: 160px;
  font-family: PTRootUI-Medium;
  font-size: 15px;
  letter-spacing: 0.42px;
}

.cta-secondary-light {
  @include cta-secondary;
  color: $color-royal-blue;

  background: $color-white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border: none;

  &.disabled {
    background: $color-white-lilac;
    border-color: $color-alto;
    color: $color-regent-gray;
  }
}

.cta-secondary-dark {
  @include cta-secondary;
  color: $color-white;
  background-color: $color-royal-blue;
  border: none;
  &.disabled {
    background: $color-regent-gray;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }
}

// ---------------------------------------
// "Need Help"/"Help a Friend" CTA buttons
// ---------------------------------------

@mixin help-cta {
  display: flex;
  flex-flow: column;

  width: 160px;
  min-width: 120px;
  overflow: hidden;
  margin: 22px 0;

  text-transform: uppercase;
  font-family: PTRootUI;
  color: $color-white;

  .top {
    display: flex;
    flex-flow: column;
    height: 32px;

    padding: 10px 0;

    border-bottom: 1px solid #484bd4;
    opacity: 0.8;

    font-size: 12px;
    letter-spacing: 1.5px;
  }

  .mid {
    display: flex;
    flex-flow: column;

    padding: 0 14px;
    margin-top: auto;

    font-size: 18px;
    letter-spacing: 0.86px;
    line-height: 19px;

    .value {
      padding-bottom: 22px;
    }
  }

  .bot {
    height: 8px;
  }
}

.need-help-cta {
  @include help-cta;
  height: 135px;
  border-radius: 10px;
  background-color: #4859ee;

  .top {
    margin-right: 24px;
    padding-left: 14px;
  }

  .bot {
    background: #d42279;
  }
}

.offer-help-cta {
  @include help-cta;
  margin-left: 20px;
  height: 135px;

  .aux {
    display: flex;

    height: 40px;
    margin-bottom: 6px;

    border-radius: 8px;
    background-color: #4859ee;

    align-items: center;
    justify-content: center;

    .notification-resolved {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;

      font-family: PTRootUI-Medium;
      font-size: 10px;
      letter-spacing: 0;

      .count {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 6px;

        width: 16px;
        height: 16px;

        background-color: red;

        border-radius: 50%;

        background: $color-white;

        font-family: PTRootUI-Bold;
        color: #4859ee;
      }
    }
  }

  .main {
    display: flex;
    flex-flow: column;
    height: 100%;

    background-color: #4859ee;
    border-radius: 10px;
    overflow: hidden;

    .top {
      margin-left: 24px;
      padding-right: 14px;
      text-align: right;
    }

    .mid {
      text-align: right;
    }

    .bot {
      background: #fed88f;
    }
  }

  &.pending-tickets {
    .main {
      .mid {
        .value {
          padding-bottom: 8px;
        }
      }
    }
  }
}
