:root {
  /* Change this to change the appearance of the hexaons */
  --hex-width: 76px;
  --hex-between: 10px;

  /* Other hexagon dimentions */
  --hex-height: calc(var(--hex-width) / 1.73 /* sqrt(3) */);
  --hex-margin: calc(var(--hex-width) / 2);
  --hex-border: calc(var(--hex-margin) / 1.73 /* sqrt(3) */);
  --hex-transition: all 0.2s ease;

  /* Colors */
  // --color-hex-default: #000000;
  --color-hex-hover: #ffffff;
  --color-angular: #dd0031;
  --color-atom: #66595c;
  --color-bootstrap: #563d7c;
  --color-circleci: #343434;
  --color-css: #1572b6;
  --color-git: #f14e32;
  --color-gulp: #da4648;
  --color-haskell: #5d4f85;
  --color-html: #e34f26;
  --color-javascript: #f7df1e;
  --color-meteor: #de4f4f;
  --color-python: #3776ab;
  --color-rails: #cc0000;
  --color-sass: #cc6699;
  --color-vuejs: #4fc08d;
  --color-webpack: #8dd6f9;
}

/* Hexagons */
.hexagon-container {
  display: grid;
  grid-template-columns: var(--hex-width) var(--hex-width) var(--hex-width) var(--hex-width);
  grid-auto-rows: calc(var(--hex-width) - 28.87px / 2);
  grid-gap: var(--hex-between) var(--hex-between);
  padding-bottom: var(--hex-border);
}

.hexagon-container-header {
  display: grid;
  grid-template-columns: var(--hex-width) var(--hex-width) var(--hex-width) var(--hex-width);
  grid-auto-rows: calc(var(--hex-width) - 28.87px / 2);
  grid-gap: var(--hex-between) var(--hex-between);
  padding-bottom: var(--hex-border);
}

.hexagon {
  align-items: center;
  background-color: var(--color-hex-default);
  cursor: pointer;
  display: flex;
  fill: white;
  height: var(--hex-height);
  justify-content: center;
  margin: var(--hex-border) 0;
  position: relative;
  transition: var(--hex-transition);
  width: var(--hex-width);
}

.hexagon::after,
.hexagon::before {
  border-left: var(--hex-margin) solid transparent;
  border-right: var(--hex-margin) solid transparent;
  content: "";
  left: 0;
  position: absolute;
  transition: var(--hex-transition);
  width: 0;
}
.hexagon::after {
  border-top: var(--hex-border) solid var(--color-hex-default);
  top: 100%;
  width: 0;
}
.hexagon::before {
  border-bottom: var(--hex-border) solid var(--color-hex-default);
  bottom: 100%;
}

.hexagon:hover {
  background-color: var(--color-hex-hover);
}
.hexagon:hover::after,
.hexagon:hover::before {
  border-top-color: var(--color-hex-hover);
  border-bottom-color: var(--color-hex-hover);
}

.hexagon:nth-child(8n + 5),
.hexagon:nth-child(8n + 6),
.hexagon:nth-child(8n + 7),
.hexagon:nth-child(8n + 8) {
  margin-left: calc(var(--hex-width) / 2 + var(--hex-between) / 2);
}

.hexagon > svg {
  // height: 75%;
  height: 208%;
  z-index: 10;
  transition: var(--hex-transition);
}

/* Colors */
.color-sass {
  background-color: var(--color-sass);
}
.color-sass::after,
.color-sass::before {
  border-top-color: var(--color-sass);
  border-bottom-color: var(--color-sass);
}
.color-sass:hover {
  fill: var(--color-sass);
}

.color-angular {
  background-color: var(--color-angular);
}
.color-angular::after,
.color-angular::before {
  border-top-color: var(--color-angular);
  border-bottom-color: var(--color-angular);
}
.color-angular:hover {
  fill: var(--color-angular);
}

.color-gulp {
  background-color: var(--color-gulp);
}
.color-gulp::after,
.color-gulp::before {
  border-top-color: var(--color-gulp);
  border-bottom-color: var(--color-gulp);
}
.color-gulp:hover {
  fill: var(--color-gulp);
}

.color-rails {
  background-color: var(--color-rails);
}
.color-rails::after,
.color-rails::before {
  border-top-color: var(--color-rails);
  border-bottom-color: var(--color-rails);
}
.color-rails:hover {
  fill: var(--color-rails);
}

.color-git {
  background-color: var(--color-git);
}
.color-git::after,
.color-git::before {
  border-top-color: var(--color-git);
  border-bottom-color: var(--color-git);
}
.color-git:hover {
  fill: var(--color-git);
}

.color-html {
  background-color: var(--color-html);
}
.color-html::after,
.color-html::before {
  border-top-color: var(--color-html);
  border-bottom-color: var(--color-html);
}
.color-html:hover {
  fill: var(--color-html);
}

.color-javascript {
  background-color: var(--color-javascript);
}
.color-javascript::after,
.color-javascript::before {
  border-top-color: var(--color-javascript);
  border-bottom-color: var(--color-javascript);
}
.color-javascript:hover {
  fill: var(--color-javascript);
}

.color-vuejs {
  background-color: var(--color-vuejs);
}
.color-vuejs::after,
.color-vuejs::before {
  border-top-color: var(--color-vuejs);
  border-bottom-color: var(--color-vuejs);
}
.color-vuejs:hover {
  fill: var(--color-vuejs);
}

.color-circleci {
  background-color: var(--color-circleci);
}
.color-circleci::after,
.color-circleci::before {
  border-top-color: var(--color-circleci);
  border-bottom-color: var(--color-circleci);
}
.color-circleci:hover {
  fill: var(--color-circleci);
}

.color-webpack {
  background-color: var(--color-webpack);
}
.color-webpack::after,
.color-webpack::before {
  border-top-color: var(--color-webpack);
  border-bottom-color: var(--color-webpack);
}
.color-webpack:hover {
  fill: var(--color-webpack);
}

.color-css {
  background-color: var(--color-css);
}
.color-css::after,
.color-css::before {
  border-top-color: var(--color-css);
  border-bottom-color: var(--color-css);
}
.color-css:hover {
  fill: var(--color-css);
}

.color-python {
  background-color: var(--color-python);
}
.color-python::after,
.color-python::before {
  border-top-color: var(--color-python);
  border-bottom-color: var(--color-python);
}
.color-python:hover {
  fill: var(--color-python);
}

.color-haskell {
  background-color: var(--color-haskell);
}
.color-haskell::after,
.color-haskell::before {
  border-top-color: var(--color-haskell);
  border-bottom-color: var(--color-haskell);
}
.color-haskell:hover {
  fill: var(--color-haskell);
}

.color-bootstrap {
  background-color: var(--color-bootstrap);
}
.color-bootstrap::after,
.color-bootstrap::before {
  border-top-color: var(--color-bootstrap);
  border-bottom-color: var(--color-bootstrap);
}
.color-bootstrap:hover {
  fill: var(--color-bootstrap);
}

.color-atom {
  background-color: var(--color-atom);
}
.color-atom::after,
.color-atom::before {
  border-top-color: var(--color-atom);
  border-bottom-color: var(--color-atom);
}
.color-atom:hover {
  fill: var(--color-atom);
}

.color-meteor {
  background-color: var(--color-meteor);
}
.color-meteor::after,
.color-meteor::before {
  border-top-color: var(--color-meteor);
  border-bottom-color: var(--color-meteor);
}
.color-meteor:hover {
  fill: var(--color-meteor);
}
