.code-confirmation {
  background-color: $color-white;

  .header {
    height: 28%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    margin-bottom: 30px;

    padding-left: $content-padding;
    padding-right: $content-padding;

    :first-child {
      margin-top: auto;
    }

    .heading,
    .paragraph {
      text-align: center;
    }
  }

  .body {
    justify-content: center;
    align-items: center;

    form {
      .cta-primary {
        margin: 40px 0;
      }
    }
  }
}
