.evaluate-ticket {
  .header {
    display: flex;
    flex-flow: column;
    height: 180px;
    background: $gradient-light-blue;

    .connections-list-header {
      display: flex;
      flex-flow: row nowrap;
      overflow-x: scroll;

      margin-top: 50px;
      padding: 0 $content-padding;

      .field-label {
        margin-top: 28px;
      }

      .connection {
        display: flex;
        flex-flow: row;

        align-items: center;

        height: 91px;

        width: calc(100vw - 72px);
        min-width: 200px;
        max-width: 320px;

        margin-right: 10px;
        margin-bottom: 10px;
        padding: 10px;

        background-color: #a6b2ec;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .avatar-wrapper {
          margin-left: 8px;
          margin-right: 18px;
          .avatar-image-container {
            height: 68px;
            width: 68px;
          }
        }

        .info-container {
          color: $color-white;
          font-family: PTRootUI;
          .name {
            font-size: 16px;
            line-height: 30px;
          }
          .circle {
            font-size: 9px;
            color: $color-white;
            text-transform: uppercase;
          }
        }

        &.active {
          background-color: $color-white;
          .info-container {
            color: $color-black-pearl-new;
            .circle {
              color: $color-regent-gray;
            }
          }
        }
      }
    }
    .current-connection {
      margin-top: 4px;
      font-family: PTRootUI;
      font-size: 9px;
      color: #ffffff;
      text-align: center;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
  }
}
