.evaluate {
  background-color: $color-white-lilac;
  .header {
    display: flex;
    height: 105px;
    background: $gradient-light-blue;

    .heading {
      padding-top: 20px;
      color: $color-white;
      margin-top: auto;
      margin-bottom: 12px;
      padding: 0 $content-padding;
    }
  }
  .body {
    padding: 0;

    .connections-list {
      padding: 0 $content-padding;

      .field-label {
        margin-top: 28px;
      }

      .connection {
        display: flex;
        flex: row;

        height: 91px;
        margin-bottom: 10px;
        align-items: center;

        padding: 10px;

        background-color: $color-white;

        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .avatar-wrapper {
          margin-left: 8px;
          margin-right: 18px;
          .avatar-image-container {
            height: 68px;
            width: 68px;
          }
        }

        .info-container {
          .name {
            font-family: PTRootUI;
            font-size: 16px;
            color: #03041b;
            line-height: 30px;
          }
          .circle {
            font-family: PTRootUI;
            font-size: 9px;
            color: $color-regent-gray;
            text-transform: uppercase;
          }
        }

        &.active {
          background-color: $color-royal-blue;
          .info-container {
            .name {
              color: $color-white;
            }
            .circle {
              color: $color-white;
            }
          }
        }
      }
    }

    .footer {
      position: fixed;
      bottom: 0;

      display: flex;
      flex-flow: row;

      z-index: 10;
      width: 100%;

      padding: 0 5.33%;
      justify-content: center;

      background-color: $color-white;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    }
  }
}
