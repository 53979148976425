.badges {
  background: $gradient-light-blue;

  .header {
    height: 60px;
    display: flex;
    padding: 0 $content-padding;

    .close-link {
      // TODO: same as nav link intro, dry
      position: absolute;
      z-index: 10;
      right: 14px;
      top: 14px;
      text-transform: uppercase;
      a {
        display: block;
        text-align: right;
        width: 120px;
        height: 40px;
      }
    }

    .heading {
      margin-top: auto;
      color: $color-white;
      font-family: PTRootUI-Light;
      font-size: 24px;
      letter-spacing: -0.2px;
    }
  }

  .body {
    height: calc(100% - 60px);
    .badges-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      margin-bottom: auto;
      .badges-grid-profile {
        margin-left: -40px;
      }
    }

    .cta-primary {
      width: 160px;
      height: 50px;
      border: 2px solid $color-white;
      border-radius: 10px;
      background-color: $color-black-pearl;
    }
  }

  .modal-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    background: linear-gradient(218deg, rgba(85, 112, 239, 0.8) 0%, rgba(71, 92, 196, 0.8) 100%);

    .modal-close {
      position: absolute;
      top: 14px;
      right: 14px;
      font-family: PTRootUI-Medium;
      font-size: 10px;
      color: $color-white;
      letter-spacing: 0.75px;
      text-transform: uppercase;
    }

    .modal-wrapper {
      position: absolute;
      width: 310px;

      top: 40px;
      left: 0;
      right: 0;
      margin: auto;
      margin-bottom: 30px;
      padding: 20px 30px;

      background: $color-white;
      box-shadow: 0 0 15px 0 #091636;
      border-radius: 10px;

      .badge-row {
        display: flex;
        flex-flow: row;
        margin-bottom: 13px;
        height: 61px;

        &.single {
          margin: 18px 0;
        }

        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 18px;

          font-family: PTRootUI;
          font-size: 14px;
          color: $color-black-pearl-new;
          letter-spacing: -0.12px;
        }
      }

      .paragraph {
        font-family: PTRootUI;
        font-size: 14px;
        color: $color-black-pearl-new;
        letter-spacing: -0.12px;
      }
    }
  }
}
