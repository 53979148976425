@mixin ticket {
  display: flex;
  flex-flow: column;
  position: relative;

  min-height: 91px;

  margin: 12px 0;
  padding-left: 18px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  overflow: hidden;

  background-color: $color-white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);

  .feedback-message {
    display: none;
  }

  .left-border {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;

    opacity: 0.3;
    background: #4859ee;
    &.is-open {
      opacity: 1;
    }
  }

  .ticket-status-icon {
    position: absolute;
    top: 3px;
    left: 7px;

    display: flex;
    flex-flow: column;

    .icon-pause {
      margin-bottom: 4px;
      width: 5px;
      height: 6px;
    }
    .icon-user {
      margin-bottom: 4px;
      width: 5px;
      height: 6px;
    }
  }

  .section-face {
    display: flex;
    flex-flow: row;
    height: 74px;

    .main {
      position: relative;
      display: flex;
      flex-flow: column;

      width: calc(100% - 56px);

      .title {
        margin-right: 10px;
        font-family: PTRootUI;
        font-size: 16px;
        color: $color-royal-blue;
        letter-spacing: 0;
      }

      .skills {
        margin-top: auto;
      }
    }

    .side {
      display: flex;
      flex-flow: column;
      width: 56px;
      height: 100%;
      padding-left: 10px;
      border-left: 1px solid #e1e2e6;
      color: #92989e;

      .deadline {
        .day-with-word {
          margin-bottom: 2px;
          font-family: PTRootUI-Bold;
          font-size: 8px;
          text-transform: uppercase;
        }
        .time-over {
          margin-top: 3px;
          font-family: PTRootUI-Medium;
          line-height: 8px;
          color: #ff6600;
          div {
            font-size: 11px;
            letter-spacing: 0;
          }
          span {
            font-size: 9px;
          }
        }
        .day-and-month {
          font-family: PTRootUI-Medium;
          font-size: 11px;
        }
        .year {
          font-family: PTRootUI-Medium;
          font-size: 9px;
        }
        .time {
          font-family: PTRootUI-Medium;
          font-size: 11px;
        }
      }

      .ticket-connections {
        display: flex;
        flex-flow: column;
        width: 12px;
        margin-top: auto;

        .icon-users {
          margin-bottom: 2px;
          fill: #92989e;
        }

        .connection-count {
          width: auto;
          text-align: center;
          font-family: PTRootUI-Medium;
          font-size: 11px;
        }
      }
    }
  }

  .section-more {
    display: none;
    margin: 10px 0;

    .description {
      margin-bottom: 10px;
      font-family: PTRootUI;
      font-size: 12px;
      color: $color-black-pearl-new;
      letter-spacing: -0.1px;
      text-align: justify;
      line-height: 1.18;
    }

    .actions {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
    }
  }

  &.paused {
    .ticket-status-icon {
      opacity: 0.4;
      .icon-pause {
        fill: #92989e;
      }
      .icon-user {
        fill: #92989e;
      }
    }
    .left-border {
      // background-color: rgba(146, 152, 158, 0.3);
      background-color: rgb(146, 152, 158);
    }
    .section-face {
      .main {
        .title {
          color: #92989e;
        }
        .skills {
          opacity: 0.5;
        }
      }
    }
    .section-more {
      .description {
        color: #92989e;
      }
    }
  }

  &.done {
    .left-border {
      // background: #d42279;
      // background: #fed88f;
    }
    .section-face {
      .main {
        .title {
          color: $color-black-pearl-new;
        }
      }
      .side {
        .deadline {
          .day-with-word {
            display: none;
          }
        }

        .ticket-connections {
          display: none;
        }
      }
    }
    &.active {
      .left-border {
        opacity: 1;
      }
    }
  }

  &.deleted {
    height: 31px;
    min-height: initial;
    padding-top: 7px;
    padding-bottom: 8px;
    .ticket-status-icon {
      display: none;
    }
    .left-border {
      display: none;
    }
    .section-face {
      .main {
        .title {
          color: #03041b;
          font-family: PTRootUI;
          font-size: 10px;
          line-height: 18px;
          text-transform: uppercase;
          line-height: 2;

          &:before {
            content: "deleted";
            margin-right: 10px;
            color: #ff544a;
            font-family: PTRootUI-Bold;
            font-size: 10px;
            color: #ff544a;
          }
        }
        .skills {
          display: none;
        }
      }
      .side {
        display: none;
      }
    }
    .section-more {
      display: none;
    }
  }
}

.own-ticket {
  @include ticket;

  &.done {
    .left-border {
      background: #d42279;
    }
  }

  .section-more {
    .actions {
      .connect,
      .finish,
      .edit,
      .pause,
      .resume,
      .duplicate {
        display: flex;
        flex-flow: column;
        align-items: center;
      }

      .connect,
      .finish,
      .resume,
      .duplicate {
        justify-content: space-between;
        width: 40.33%;
        height: 81px;
        border: 2px solid $color-royal-blue;
        border-radius: 5px;
        text-transform: uppercase;

        svg {
          margin: auto 0;
        }

        .text {
          margin-bottom: 5px;
          font-family: PTRootUI-Medium;
          font-size: 10px;
          color: $color-black-pearl-new;
          letter-spacing: 0.28px;
          text-align: center;
        }

        &.active {
          background-color: $color-royal-blue;
          svg {
            path {
              fill: $color-white;
            }
          }
          .text {
            color: $color-white;
          }
        }

        &.disabled {
          border: 2px solid #92989e;

          svg {
            path {
              fill: #92989e;
            }
          }
          .text {
            color: #92989e;
          }
        }
      }

      .edit-pause {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 16.39%;
      }

      .edit,
      .pause {
        justify-content: center;
        height: 38px;
        border: 2px solid $color-royal-blue;
        border-radius: 5px;
      }

      .resume,
      .duplicate {
        margin-left: auto;
        margin-right: auto;
        width: 127.5px;
      }
    }

    &.visible {
      display: inline;
    }
  }

  .section-finish {
    margin-top: 10px;

    .actions {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      .evaluate-btn,
      .delete {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48%;
        height: 50px;
        border: 2px solid #ff544a;
        border-radius: 25px;

        font-family: PTRootUI-Medium;
        font-size: 15px;
        color: $color-black-pearl-new;
        letter-spacing: 0.42px;
        text-align: center;
      }

      .evaluate-btn {
        border: 2px solid #4859ee;
        border-radius: 25px;
        &.disabled {
          opacity: 0.3;
        }
      }
    }
  }
}

.matched-ticket {
  @include ticket;

  &.done {
    .left-border {
      background: #fed88f;
    }
    .ticket-status-icon {
      display: none;
    }
  }

  .section-more {
    .actions {
      justify-content: center;

      .accept,
      .decline {
        display: flex;
        flex-flow: row;

        align-items: center;
        justify-content: space-between;

        padding: 0 8px;
        margin: 0 2px;

        width: 117px;
        height: 38px;
        border: 2px solid $color-royal-blue;
        border-radius: 5px;
        text-transform: uppercase;

        svg {
          height: 24px;
          width: 24px;
          stroke-width: 1;
        }

        .text {
          font-family: PTRootUI-Medium;
          font-size: 10px;
          color: $color-black-pearl-new;
          letter-spacing: 0.28px;
          text-align: center;
        }

        &.active {
          background-color: $color-royal-blue;
          .icon-check {
            path {
              fill: $color-white;
            }
          }
          .icon-cross {
            stroke: $color-white;
          }
          .text {
            color: $color-white;
          }
        }

        &.disabled {
          border: 2px solid #92989e;

          svg {
            path {
              fill: #92989e;
            }
          }
          .text {
            color: #92989e;
          }
        }
      }
    }

    &.visible {
      display: inline;
    }
  }

  .section-accept {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    .field-label {
      margin-top: 16px;
      margin-bottom: 12px;
      text-align: center;
    }
    .actions {
      display: flex;
      flex-flow: row;
      justify-content: center;

      .slack,
      .email {
        display: flex;
        flex-flow: row;

        align-items: center;
        justify-content: space-between;

        padding: 0 8px;
        margin: 0 2px;

        width: 117px;
        height: 44px;
        border: 2px solid $color-royal-blue;
        border-radius: 5px;
        text-transform: uppercase;

        .text {
          font-family: PTRootUI-Medium;
          font-size: 10px;
          color: $color-black-pearl-new;
          letter-spacing: 0.28px;
          text-align: center;
        }

        &.active {
          background-color: $color-royal-blue;
          .text {
            color: $color-white;
          }
        }

        &.disabled {
          border: 2px solid #92989e;

          svg {
            path {
              fill: #92989e;
            }
          }
          .text {
            color: #92989e;
          }
        }
      }
    }
  }

  .section-submit {
    margin-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    .cta {
      margin-top: 10px;
      margin-bottom: 4px;
    }
  }

  .section-decline {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    .field-label {
      margin-top: 16px;
      margin-bottom: 12px;
      text-align: center;
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .reason {
        font-family: PTRootUI-Medium;
        font-size: 10px;
        color: #4859ee;
        letter-spacing: 0;
        line-height: 28px;
      }
    }
  }
}

.evaluation-ticket {
  @include ticket;
  .section-more {
    display: block;
    margin: 10px 0;

    .description {
      margin-bottom: 10px;
      font-family: PTRootUI;
      font-size: 12px;
      color: $color-black-pearl-new;
      letter-spacing: -0.1px;
      text-align: justify;
      line-height: 1.18;
    }

    .actions {
      display: none;
    }
  }
}

.paused-tickets-container {
  margin-top: 16px;
  // prettier-ignore
  &.stacked {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow:
    0 -5px 0 #f5f5f5,
    0 -5px 3px rgba(0, 0, 0, 0.15);

    .matched-ticket {
      margin-top: 0;
      // box-shadow: 0 0 3px 3px rgba(0,0,0,0.15);
    }
  }
}

.deleted-tickets-container {
  margin-top: 16px;
  // prettier-ignore
  &.stacked {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow:
    /* The top layer shadow */ 
    // 0 -1px 1px rgba(0, 0, 0, 0.15), 
    /* The second layer */ 
    0 -4px 0 #f5f5f5,
    /* The second layer shadow */ 
    0 -4px 1px rgba(0, 0, 0, 0.15), 
    /* The third layer */ 
    0 -8px 0 #f5f5f5,
    /* The third layer shadow */ 
    0 -8px 1px rgba(0, 0, 0, 0.15);

    .matched-ticket {
      // box-shadow: none;

      // box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
      // border: 1px solid rgba(0, 0, 0, 0.05);
      // box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.3);
      // box-shadow: 0 4px 1px 1px rgba(0, 0, 0, 0.15);
      // margin-top: 0;
    }
  }
}

.feedback-ticket {
}
