.add-skills {
  .header {
    display: flex;
    flex-flow: column;

    min-height: 180px;
    background: $gradient-light-blue;

    .heading {
      margin-top: auto;
      margin-bottom: 12px;
      padding: 12px $content-padding;

      width: 320px;

      color: $color-white;
    }
  }
  .body {
    min-height: calc(100% - 180px);

    .paragraph {
      margin-top: 34px;
      margin-bottom: 20px;
    }

    .section-search-input {
      .input-field {
        margin-bottom: 16px;
      }
    }
    .section-search-results {
      margin: 12px 0;
    }

    .section-search-selected {
      margin-top: 10px;
      padding-top: 26px;
      border-top: solid 1px $color-mischka;
      .tag {
        border-color: $color-alto;
      }
    }

    .footer {
      .cta-primary {
        margin-bottom: 40px;
      }
    }
  }
}
