// $content-padding: 20px;
$content-padding: 5.33%;

$app-container-min-width: 320px;
$app-container-max-width: 768px;

$app-container-min-height: 640px;
$app-container-max-height: 821px;

$color-white: #ffffff;

$color-blue-ribbon: #005fff;
$color-blue-ribbon-rgba: rgba(0, 95, 255, 0.03);

$color-royal-blue: #4859ee;

$color-hit-gray: #9aa7b0;
$color-pattens-blue: #dcf4ff;
$color-alice-blue: #f8fcff;

$color-pomergrante: #ec3f13;
$color-sunset-orange: #ff544a;

$color-rose-bud: #f9b7a6;

$color-golden-bell: #ec8413;
$color-sunshade: #ffac33;
$color-turbo: #fff000;

$color-malachite: #00ba2a;

$color-black-pearl: #091636;
$color-black-pearl-new: #03041b;
$color-scorpion: #5b5b5b;
$color-dusty-gray: #9a9a9a;
$color-regent-gray: #92989e;
$color-mischka: #e1e2e6;
$color-alto: #dbdbdb;
$color-wild-sand: #f4f4f4;
$color-white-lilac: #f7f8fc;

$color-dove-gray: #707070;

$gradient-light-blue: linear-gradient(218deg, #5570ef 0%, #475cc4 100%);

// background-image: linear-gradient(218deg, #5570EF 0%, #475CC4 100%);

// 4859EE
