.edit-ticket {
  display: flex;
  flex-flow: column;

  min-height: 100%;
  padding: 28px $content-padding;

  .field-label {
    display: flex;
    margin-bottom: 0;
    .remaining {
      margin-left: auto;

      color: $color-regent-gray;

      font-family: PTRootUI;
      font-size: 10px;
      letter-spacing: 0px;
      text-transform: lowercase;

      &.warning {
        color: $color-pomergrante;
      }
    }
  }

  .input-field-title {
    margin-bottom: 30px;
  }

  .input-field-description {
    margin-bottom: 24px;
  }

  .tags-container {
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .deadline-inputs {
    display: flex;
    flex-flow: row;
    margin-top: 8px;
    margin-bottom: 28px;
    input {
      margin-right: 10px;
    }
  }

  .deadline {
    &.error {
      color: #ff544a;
    }
    .error-msg {
      margin-left: 8px;
      color: #92989e;
      text-transform: initial;
      font-family: PTRootUI-Medium;
      font-size: 11px;
      letter-spacing: 0.5px;
    }
  }

  .circles {
    display: flex;
    flex-flow: row;
    margin-top: 8px;
    margin-bottom: 28px;

    .input-field-connection {
      margin-right: 10px;
    }
  }

  .cta {
    margin-top: auto;
  }
}
