.intro {
  background-color: $color-white;

  .header {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .intro-bg-image {
      width: 100%;
    }

    .nav-link-intro {
      position: absolute;
      z-index: 10;
      right: 14px;
      top: 14px;
      text-transform: uppercase;
      a {
        display: block;
        text-align: right;
        width: 120px;
        height: 40px;
      }
    }

    .nav-bar {
      position: absolute;
      z-index: 10;
      margin-left: auto;
      margin-right: auto;
      bottom: 14px;
    }
  }

  .body-intro {
    padding: 40px $content-padding;
    list-style-position: inside;
    .paragraph {
      margin-bottom: 40px;

      ul {
          list-style: none;
          padding:0;
          margin:0;

        li{
          list-style-position: inside;
          padding: 5px 0 5px 20px;
          text-indent: -1em;
          &:before {
            content: "✓ ";
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
    .cta-primary {
      margin-top: auto;
      margin-bottom: 40px;
    }
  }
}

@media screen and (min-width: 600px) {
  .intro {
    .header {
      position: fixed;
      height: 430px;
      width: 100%;

      .intro-bg-image {
        width: 1440px;
        height: 430px;
      }
    }
    .body {
      margin-top: 403px;
    }
  }
}
