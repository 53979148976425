.support {
  .body {
    text-align: center;
    .heading {
      margin-top: 100px;
    }

    .paragraph {
      margin-bottom: 40px;
    }

    .highlight {
      font-family: PTRootUI-Medium;
    }
  }
}
