.job-titles {
  display: flex;
  flex-flow: column;
  min-height: 100%;
  padding: 28px $content-padding;

  background-color: $color-white-lilac;

  .field-label {
    margin-bottom: 0;
  }

  .jobs {
    padding-top: 12px;
    padding-bottom: 6px;
    .job {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 7px;

      .logo-container-small {
        margin-right: 24px;
      }
    }
  }

  .past-job-titles {
    padding-top: 30px;
    border-top: 1px solid #e1e2e6;
    .logo-container-small {
      img {
        opacity: 0.3;
      }
    }
  }

  .edit-skills-link {
    display: block;
    margin-top: auto;

    color: $color-royal-blue;
    text-align: right;
    text-transform: uppercase;
    line-height: 13px;
  }
}
