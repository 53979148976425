.connections {
  padding: 28px $content-padding;

  .field-label {
    margin-bottom: 0;
  }

  .circle-tabs {
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    margin-top: 18px;
    margin-bottom: 28px;
    padding-bottom: 18px;

    border-bottom: 1px solid #e1e2e6;

    .tab {
      margin-right: 10px;
    }
  }

  .circles {
    .circle {
      display: flex;
      flex-flow: row nowrap;

      padding: 26px 0;
      border-bottom: 1px solid $color-mischka;

      .circle-info {
        display: flex;
        flex-flow: column;
        margin-left: 22px;

        .circle-members {
          display: flex;
          align-items: center;
          margin-top: 8px;

          font-family: PTRootUI-Medium;
          font-size: 14px;
          color: $color-black-pearl;
          letter-spacing: 0.98px;

          .count {
            font-size: 11px;
          }

          svg {
            height: 15px;
            width: 26px;
            margin-right: 6px;
          }
        }

        .circle-notifications {
          display: flex;
          align-items: center;
          margin-top: 8px;

          .field-label {
            margin-bottom: 0;
          }

          .notification-icon-container {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 24px;
            height: 24px;
            margin-left: 12px;

            border-radius: 3px;
            background: #ffffff;

            opacity: 0.3;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.7);

            img {
              max-width: 16px;
              max-height: 16px;
            }

            &.active {
              opacity: 1;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
