.logo-container {
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;

  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: $color-white;
  border-radius: 5px;
  overflow: hidden;

  &.disabled {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    img {
      opacity: 0.3;
    }
  }

  &.single {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }

  img {
    margin: 20px;
    max-height: 50px;
    max-width: 50px;
  }
}

.logo-container-big {
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;

  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  // background: $color-white;
  border-radius: 5px;
  overflow: hidden;

  img {
    margin: 20px;
    max-height: 80px;
    max-width: 80px;
  }
}

.logo-container-small {
  width: 35px;
  height: 35px;

  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  img {
    max-height: 25px;
  }
}

.avatar-wrapper {
  position: relative;
  .avatar-image-container {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;

    .avatar-image {
      height: auto;
      max-height: 100%;
    }
  }
}

.communication-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  margin-left: 10px;

  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  svg {
    // display: none;
    height: 20px;
    width: 20px;
  }
}
