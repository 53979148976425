.close-link {
  position: absolute;
  z-index: 10;
  right: 14px;
  top: 14px;
  text-transform: uppercase;
  a {
    display: block;
    text-align: right;
    width: 120px;
    height: 40px;
  }
}
