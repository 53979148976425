.badges-grid-header {
  display: flex;
  flex-flow: row;

  [class^="column"] {
    display: flex;
    flex-flow: column;

    pointer-events: none;
    z-index: 10;

    // All but last column moves to the right
    &:not(:last-child) {
      margin-right: -14px;
    }

    .badge {
      z-index: 20;
      pointer-events: all;
      // All but first badges moves up into it's neighbor
      &:not(:first-child) {
        margin-top: -6px;
      }

      // Each odd badge moves to right
      &:nth-child(odd) {
        margin-left: 17px;
      }
    }

    // refactor to png
    .badge-png {
      pointer-events: all;
      // All but first badges moves up into it's neighbor
      &:not(:first-child) {
        margin-top: -6px;
      }

      // Each odd badge moves to right
      &:nth-child(odd) {
        margin-left: 17px;
      }
    }
  }
}

.badges-grid-profile {
  display: flex;
  flex-flow: row;

  [class^="column"] {
    display: flex;
    flex-flow: column;

    pointer-events: none;
    z-index: 10;

    // All but last column moves to the right
    &:not(:last-child) {
      margin-right: -33px;
    }

    .badge {
      z-index: 20;
      pointer-events: all;
      // All but first badges moves up into it's neighbor
      &:not(:first-child) {
        margin-top: -16px;
      }

      // Each odd badge moves to right
      &:nth-child(odd) {
        margin-left: 42px;
      }

      &.level-0 {
        // opacity: 50%;
        fill-opacity: 0.6;
        // display: none;
      }
    }

    // refactor to png
    .badge-png {
      pointer-events: all;
      // All but first badges moves up into it's neighbor
      &:not(:first-child) {
        margin-top: -16px;
      }

      // Each odd badge moves to right
      &:nth-child(odd) {
        margin-left: 42px;
      }

      &.level-0 {
        // opacity: 50%;
        fill-opacity: 0.6;
        // display: none;
      }
    }
  }
}
