.opened-tickets {
  padding: 0 $content-padding;
  min-height: 100%;

  .sort {
    display: flex;
    flex-flow: row;
    margin-top: 22px;
    margin-bottom: 22px;

    font-family: PTRootUI-Bold;
    font-size: 10px;
    color: #a6b2ec;
    letter-spacing: 0.7px;

    .deadline-people {
      display: flex;
      flex-flow: row;
      align-items: center;
      text-transform: uppercase;

      .toggle-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0 10px;
        width: 32px;
        height: 22px;
        background-color: $color-royal-blue;
        border-radius: 11px;

        .toggle-btn {
          width: 16px;
          height: 16px;
          background-color: white;
          border-radius: 50%;
          margin: 0 2px;
        }
      }

      &.sort-by-deadline {
        .toggle-btn-container {
          justify-content: flex-start;
        }
        .deadline-text {
          color: $color-royal-blue;
        }
      }
      &.sort-by-people {
        .people-text {
          color: $color-royal-blue;
        }
      }
    }

    .pause {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-left: auto;
      text-transform: uppercase;

      &.active {
        color: $color-royal-blue;
      }

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 21px;
        height: 21px;
        margin-left: 10px;

        border: 2px solid $color-royal-blue;
        border-radius: 3px;

        .icon-check {
          display: none;
          width: 14px;
          height: 9.5px;
        }
        &.active {
          color: red;
          .icon-check {
            display: initial;
          }
        }
      }
    }
  }
}
