.add-job-title {
  background-color: $color-white;

  .header {
    display: flex;
    flex-flow: column;
    flex: 1;
    background: $gradient-light-blue;

    .heading {
      padding-top: 20px;
      color: $color-white;
      margin-top: auto;
      margin-bottom: 12px;
      padding: 0 $content-padding;
    }
  }

  .body {
    flex: 3;
    .circle-section {
      display: flex;
      align-items: center;
      margin-top: 30px;
      min-height: 80px;

      .paragraph {
        margin-bottom: 24px;
        margin-top: 0;
        margin-left: 20px;
      }
    }

    .field-label {
      margin-top: 18px;
      margin-bottom: 12px;
    }

    .section-search-input {
      margin-top: 20px;
    }

    .section-search-selected {
      border-top: solid 1px $color-mischka;
      margin-bottom: 20px;
    }

    .cta-primary {
      margin-top: auto;
      margin-bottom: 40px;
      // display: none;
    }
  }
}
