.connect {
  .header {
    display: flex;
    height: 105px;
    background: $gradient-light-blue;

    .heading {
      padding-top: 20px;
      color: $color-white;
      margin-top: auto;
      margin-bottom: 12px;
      padding: 0 $content-padding;
    }
  }
  .body {
    .field-label {
      margin-top: 28px;
    }

    .connections-list {
      .connection {
        display: flex;
        flex: row;

        height: 91px;
        margin-bottom: 10px;
        align-items: center;

        padding: 10px;

        background-color: $color-white;

        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .avatar-wrapper {
          margin-left: 8px;
          margin-right: 18px;
          .avatar-image-container {
            height: 68px;
            width: 68px;
          }
        }

        .info-container {
          .name {
            font-family: PTRootUI;
            font-size: 16px;
            color: #03041b;
            line-height: 30px;
          }
          .circle {
            font-family: PTRootUI;
            font-size: 9px;
            color: $color-regent-gray;
            text-transform: uppercase;
          }
        }

        .channels {
          display: flex;
          flex-flow: column;
          margin-left: auto;
          height: 100%;

          :nth-child(2) {
            margin-top: auto;
          }

          .channel {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .slack,
            .handle {
              display: none; // Show only when active
              font-family: PTRootUI;
              font-size: 10px;
              color: #ffffff;
              text-align: right;
            }
            .channel-logo {
              display: flex;
              justify-content: center;
              align-items: center;

              width: 32px;
              height: 32px;

              margin-left: 10px;

              background: #ffffff;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
              border-radius: 4px;
            }
          }
        }

        &.active {
          background: #4859ee;
          .avatar-wrapper {
            display: none;
          }
          .info-container {
            display: none;
          }
          .channels {
            .channel {
              display: flex;
              flex-flow: row;
              .handle {
                display: inline;
              }
            }
          }
        }
      }
    }
  }
}
