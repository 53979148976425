html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Remove Gray Highlight When Tapping Links
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  // NOTE: Removes scroll bars
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    height: 0px;
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a {
  // NOTE: Disables blue overlay on <a> tap event, turn on for debugging
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
}

button,
input[type="reset"],
input[type="button"] {
  border: none;
  -webkit-appearance: none;
}

*:focus {
  outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.update-notification {
  background-color: #222;
  color: #fff;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.8em 1.2em;
  text-decoration: none;
}
