.add-job-titles {
  background-color: $color-white-lilac;
  position: relative;
  .header {
    color: $color-white;
    display: flex;
    flex-direction: column;

    display: flex;
    flex-flow: column;
    height: 105px;
    padding: 0 $content-padding;
    padding-bottom: 14px;

    background: $gradient-light-blue;

    .heading {
      margin-top: auto;
      font-family: PTRootUI-Light;
    }
  }

  .body {
    display: flex;
    flex-direction: column;

    min-height: calc(100% - 105px);
    padding-top: 30px;
    padding-bottom: 30px;

    .uppercase-label-bold {
      margin: 0;
    }

    .circle-tabs {
      display: flex;
      flex-flow: row nowrap;
      flex-shrink: 0;
      margin-top: 18px;
      margin-bottom: 28px;

      .tab {
        margin-right: 10px;
      }
    }

    .section-search-input {
      margin-top: 2px;
      margin-bottom: 16px;
    }

    .tags-container {
      margin: 20px 0;
    }

    .jobs {
      margin-top: auto;
      border-top: 1px solid #e1e2e6;

      hr {
        display: none;
        border-top: 1px solid red;
      }

      .uppercase-label-bold {
        margin-top: 24px;
        margin-bottom: 24px;
      }

      .job {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .finish-toggle {
          width: 30px;
          margin-right: 22px;
          // margin-left: 4px;
          display: flex;
          justify-content: center;
          // align-items: center;
        }

        .logo-container-small {
          margin-right: 24px;
        }
      }
    }
  }
}
